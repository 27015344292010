import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { graphql } from "gatsby";
import { Divider, Grid, Stack, Avatar } from "@mui/material";
import AvatarImage from "assets/images/avatar.jpg";
import Tag from "components/tag";
import { parseArticle, parseArticles } from "utils/article";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ShareModal from "components/share-modal";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Gitalk from "gatsby-plugin-gitalk";
import "assets/css/gitalk.css";
import CardArticle from "components/card-article";
import Title from "components/title";
import ArticleButtons from "components/article-buttons";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TableOfContents from "components/table-of-contents";

export default function Article({ data }: any) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const article = parseArticle(data.mdx);
  const relatedArticles = parseArticles(data.relatedMdxs.posts.slice(0, 3));

  const [open, setOpen] = React.useState(false);

  let gitalkConfig = {
    id: article.slug,
    title: article.title,
  };

  React.useEffect(() => {
    console.log({ toc: article.tableOfContents });
  }, [article]);

  // sample path: "/Users/malfinsyahruddin/code/github/blog/src/articles/object-detection-pada-ios.mdx"
  // return: object-detection-pada-ios
  function getSlugFromAbsolutePath(path: String): String {
    console.log(`getSlugFromAbsolutePath: ${path}`);
    return path.split("articles/")[1].replaceAll(".mdx", "");
  }

  return (
    <div
      style={{
        paddingTop: "64px",
        paddingBottom: "64px",
        minHeight: "100vh",
      }}
    >
      <Helmet
        title={article.title}
        titleTemplate="%s | Alfin's Blog"
        meta={[
          {
            name: `description`,
            content: article.subtitle,
          },
          {
            name: "twitter:image",
            content: article.hero_image,
          },
        ]}
      />

      <GatsbySeo
        openGraph={{
          title: article.title,
          description: article.subtitle,
          url: `/article/${article.slug}`,
          type: "article",
          article: {
            publishedTime: article.date.toString(),
            authors: ["https://alfin.dev"],
            tags: article.tags,
          },
          images: [
            {
              url: article.hero_image,
              width: 1920,
              height: 1080,
              alt: "Hero Image",
            },
          ],
        }}
      />

      <Grid container justifyContent="center" sx={{ px: isMobile ? 2 : 6 }}>
        <Grid
          item
          xs={12}
          container
          justifyContent={
            article?.tableOfContents?.items ? "flex-start" : "center"
          }
          spacing={4}
        >
          {!isMobile && article?.tableOfContents?.items && (
            <Grid item sm={3}>
              <div
                style={{
                  position: "sticky",
                  top: 75,
                  overflow: "auto",
                  maxHeight: "100vh",
                  paddingBottom: 100,
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                }}
                id="toc"
              >
                <TableOfContents items={article.tableOfContents.items} />
              </div>
            </Grid>
          )}

          <Grid item xs={12} sm={9} md={6} className="mdx">
            <img
              src={article.hero_image}
              alt="hero image"
              style={{
                width: "100%",
                borderRadius: 15,
                border: "1px solid",
                borderColor:
                  theme.palette.mode == "dark"
                    ? "rgba(255,255,255,0.25)"
                    : "rgba(0,0,0,0.15)",
              }}
            />

            <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>
              {article.title}
            </Typography>
            <Typography variant="h6" color="text.secondary">
              {article.subtitle}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.disabled"
              sx={{ fontFamily: "Helvetica", my: 2 }}
            >
              {article.formattedDate} · {article.time} Minutes
            </Typography>

            {isMobile && (
              <div>
                {article?.tableOfContents?.items && (
                  <TableOfContents items={article.tableOfContents.items} />
                )}
              </div>
            )}

            <div className="mdx-body">
              <MDXRenderer>{article.body}</MDXRenderer>
            </div>

            <Box sx={{ my: 4 }}>
              {article.tags.map((tag: string) => (
                <Tag>{tag}</Tag>
              ))}
            </Box>

            <Divider sx={{ my: 4 }} />

            {/* <Grid
            container
            justifyContent="space-between"
            spacing={2}
            direction={isMobile ? "column-reverse" : "row"}
          >
            <Grid item xs={12} md={10}>
              <Author
                photo={AvatarImage}
                name="Alfin Syahruddin"
                description="Developer · Stock Trader · Libertarian · Freethinker"
                description2="Always open to new ideas."
              />
            </Grid>

            <ArticleButtons
              slug={article.slug}
              handleShare={() => {
                setOpen(true);
              }}
            />
          </Grid> */}

            <Author
              photo={AvatarImage}
              name="Alfin Syahruddin"
              description="Developer · Stock Trader · Libertarian · Freethinker"
              description2="Always open to new ideas. 🕊️"
            />

            <ArticleButtons
              slug={article.slug}
              handleShare={() => {
                setOpen(true);
              }}
            />

            <div id="comment-section" />
            <Divider sx={{ my: 4 }} />

            <div className={theme.palette.mode}>
              <Gitalk options={gitalkConfig} />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={4} sx={{ my: 4 }}>
            <Grid item xs={12}>
              <Title>Related articles</Title>
              <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
                Articles that you might want to read.
              </Typography>
            </Grid>
            {relatedArticles.map((article: any, index: number) => (
              <Grid item xs={12} md={4} key={index}>
                <CardArticle
                  image={article.hero_image}
                  to={`/article/${getSlugFromAbsolutePath(
                    article.fileAbsolutePath
                  )}`}
                  title={article.title}
                  subtitle={article.subtitle}
                  tags={article.tags}
                  date={article.formattedDate}
                  time={article.time}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <ShareModal open={open} setOpen={setOpen} />
    </div>
  );
}

const Author: React.FC<{
  photo: string;
  name: string;
  description: string;
  description2: string;
}> = ({ photo, name, description, description2 }) => {
  return (
    <Grid item xs={12} container spacing={2} justifyContent="flex-start">
      <Grid item>
        <Avatar
          src={photo}
          sx={{
            width: 56,
            height: 56,
            border: "1px solid",
            borderColor: "rgba(255,255,255,0.25)",
            pointerEvents: "none",
          }}
        />
      </Grid>
      <Grid item xs={9}>
        <Stack>
          <Typography variant="caption" color="text.disabled">
            Written by :
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {name}
          </Typography>
          <Typography
            variant="caption"
            color="text.disabled"
            sx={{ textTransform: "uppercase" }}
          >
            {description}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {description2}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        date
        formattedDate: date(formatString: "D MMMM YYYY")
        tags
        hero_image
      }
      id
      slug
      body
      fields {
        readingTime {
          minutes
        }
      }
      tableOfContents
    }

    relatedMdxs(parent: { id: { eq: $id } }) {
      posts {
        frontmatter {
          title
          subtitle
          date
          formattedDate: date(formatString: "D MMMM YYYY")
          tags
          hero_image
        }
        id
        fileAbsolutePath
        body
        fields {
          readingTime {
            minutes
          }
        }
      }
    }
  }
`;
