import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore/lite";

const firebaseConfig = {
    apiKey: "AIzaSyCRwQTNJnEjjevEOVb6mNvptN8hZQltfFs",
    authDomain: "blog-ee50e.firebaseapp.com",
    projectId: "blog-ee50e",
    storageBucket: "blog-ee50e.appspot.com",
    messagingSenderId: "398063214504",
    appId: "1:398063214504:web:7dc4fcb867bed62068c79d",
    measurementId: "G-6LB044YFVT"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {app, db}