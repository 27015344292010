import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function Icon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M23.1593 5.22275C23.4417 5.70486 23.6552 6.22416 23.7935 6.76551C23.9389 7.32505 24.0081 7.90165 23.9992 8.47969C24.0062 9.58182 23.716 10.6655 23.1593 11.6166C22.885 12.1137 22.537 12.5714 22.1308 12.9708L12 23.1016L1.8692 12.9708C1.46263 12.5674 1.11105 12.112 0.823555 11.6166C0.55735 11.1347 0.350157 10.6225 0.206451 10.091C0.0693164 9.56477 0.000749228 9.02309 0.000749228 8.47969C-0.00807696 7.90165 0.0611141 7.32506 0.206451 6.76551C0.479641 5.66367 1.05559 4.66021 1.8692 3.86855C2.46407 3.27568 3.16852 2.8041 3.94336 2.48007C5.50299 1.83998 7.25199 1.83998 8.81162 2.48007C9.54398 2.79006 10.2168 3.22509 10.8001 3.7657L10.8858 3.86855L12 4.98277L13.1142 3.86855L13.1999 3.7657C13.7832 3.22509 14.456 2.79006 15.1884 2.48007C16.748 1.83998 18.497 1.83998 20.0566 2.48007C20.8315 2.8041 21.5359 3.27568 22.1308 3.86855C22.5411 4.26448 22.888 4.72123 23.1593 5.22275Z" />
    </SvgIcon>
  );
}

export default Icon;
