import React from "react";
import CircleButton from "components/forms/circle-button";
import HeartOutlinedIcon from "assets/icons/heart-outlined";
import HeartIcon from "assets/icons/heart";
// import { onSnapshot } from "firebase/firestore";
import { Grid, Typography } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function LikeButton({
  hideLikesCount = false,
  isLiked,
  likes,
  handleLike,
}: {
  hideLikesCount?: boolean;
  isLiked: boolean;
  likes: number | null;
  handleLike: () => void;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      // direction={isMobile ? "row-reverse" : "row"}
    >
      <Grid item>
        <CircleButton
          disabled={likes == null}
          color={isLiked ? "#FE8E86" : null}
          tooltip={isLiked ? "Unlike" : "Like"}
          Icon={isLiked ? HeartIcon : HeartOutlinedIcon}
          onClick={handleLike}
          iconProps={{ style: { fontSize: 20 } }}
        />
      </Grid>
      {!hideLikesCount && (
        <Grid item>
          <Typography variant="subtitle2" color="text.secondary">
            {likes != null
              ? `${likes} ${likes == 1 ? "LIKE" : "LIKES"}`
              : "Loading..."}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
