import React from "react";
import FloatingButtons from "./floating-buttons";
import StaticButtons from "./static-buttons";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { db } from "config/firebase";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  increment,
} from "firebase/firestore/lite";

export default function ArticleButtons(props: {
  slug: String;
  handleShare: () => void;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isLiked, setLiked] = React.useState(false);
  const [likes, setLikes] = React.useState<number | null>(null);

  const docRef = doc(db, "blog", String(props.slug));

  // set initial isLiked
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      setLiked(
        JSON.parse(localStorage.getItem("LIKED_ARTICLES") ?? "[]").includes(
          props.slug
        )
      );
    }
  }, []);

  React.useEffect(() => {
    (async () => {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setLikes(docSnap.data().likes);
      } else {
        setLikes(0);
        await setDoc(docRef, {
          likes: 0,
        });
      }
    })();
  }, []);

  // REALTIME LIKES COUNT
  // React.useEffect(() => {
  //   const unsub = onSnapshot(docRef, (doc: any) => {
  //     console.log("Current data: ", doc.data());
  //     setLikes(doc.data().likes);
  //   });
  //   return () => unsub();
  // }, []);

  async function handleLike() {
    const isLikedParam = !isLiked;
    setLiked(isLikedParam);
    saveLikes(isLikedParam);
  }

  async function saveLikes(isLiked: boolean) {
    // 1. Save to local storage
    const likedArticles = JSON.parse(
      localStorage.getItem("LIKED_ARTICLES") ?? "[]"
    );
    if (isLiked) {
      setLikes((likes ?? 0) + 1);

      await updateDoc(docRef, {
        likes: increment(1),
      });

      localStorage.setItem(
        "LIKED_ARTICLES",
        JSON.stringify([...likedArticles, props.slug])
      );
    } else {
      setLikes((likes ?? 0) - 1);

      await updateDoc(docRef, {
        likes: increment(-1),
      });

      localStorage.setItem(
        "LIKED_ARTICLES",
        JSON.stringify(
          likedArticles.filter((item: string) => item != props.slug)
        )
      );
    }
  }

  return (
    <>
      {/* {isMobile ? ( */}
      <FloatingButtons
        isLiked={isLiked}
        likes={likes}
        handleLike={handleLike}
        handleShare={props.handleShare}
      />
      {/* ) : ( */}
      {/* <StaticButtons
        isLiked={isLiked}
        likes={likes}
        handleLike={handleLike}
        handleShare={props.handleShare}
      /> */}
      {/* )} */}
    </>
  );
}
