import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function Icon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M23.7935 6.76551C23.6552 6.22416 23.4417 5.70486 23.1593 5.22275C22.888 4.72123 22.5411 4.26448 22.1308 3.86855C21.5359 3.27568 20.8315 2.8041 20.0566 2.48007C18.497 1.83998 16.748 1.83998 15.1884 2.48007C14.456 2.79006 13.7832 3.22509 13.1999 3.7657L13.1142 3.86855L12 4.98277L10.8858 3.86855L10.8001 3.7657C10.2168 3.22509 9.54398 2.79006 8.81162 2.48007C7.25199 1.83998 5.50299 1.83998 3.94336 2.48007C3.16852 2.8041 2.46407 3.27568 1.8692 3.86855C1.05559 4.66021 0.479641 5.66367 0.206451 6.76551C0.0611141 7.32506 -0.00807697 7.90165 0.000749228 8.47969C0.000749228 9.02309 0.0693164 9.56477 0.206451 10.091C0.350157 10.6225 0.55735 11.1347 0.823555 11.6166C1.11105 12.112 1.46263 12.5674 1.8692 12.9708L12 23.1016L22.1308 12.9708C22.537 12.5714 22.885 12.1137 23.1593 11.6166C23.716 10.6654 24.0062 9.58182 23.9992 8.47969C24.0081 7.90165 23.9389 7.32505 23.7935 6.76551V6.76551ZM22.0794 9.55962C21.874 10.3429 21.4661 11.0582 20.8966 11.6338L11.9657 20.5475L3.03484 11.6338C2.74382 11.3407 2.49045 11.0125 2.28061 10.6567C2.08314 10.3047 1.9278 9.93076 1.81778 9.54248C1.72994 9.15425 1.68397 8.75773 1.68064 8.3597C1.68297 7.95029 1.72894 7.54229 1.81778 7.14263C1.92457 6.75324 2.08008 6.37888 2.28061 6.02842C2.48631 5.66844 2.74001 5.34275 3.03484 5.05134C3.47521 4.61687 3.99255 4.2681 4.56046 4.02283C5.70487 3.56501 6.98154 3.56501 8.12595 4.02283C8.69163 4.25767 9.20246 4.60222 9.63443 5.03419L11.9657 7.38262L14.297 5.03419C14.7287 4.60146 15.2412 4.25782 15.8055 4.02283C16.9499 3.56501 18.2266 3.56501 19.371 4.02283C19.9384 4.26796 20.456 4.61765 20.8966 5.05134C21.1948 5.33417 21.4451 5.6633 21.6337 6.02842C22.0307 6.7286 22.2376 7.52051 22.2336 8.32542C22.257 8.7396 22.2223 9.15501 22.1308 9.55962H22.0794V9.55962Z" />
    </SvgIcon>
  );
}

export default Icon;
