import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function Icon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3025 2.76433C11.4887 1.63465 13.0975 1 14.775 1H18.225C18.5426 1 18.8 1.24518 18.8 1.54762V5.92857C18.8 6.23101 18.5426 6.47619 18.225 6.47619H14.775C14.6225 6.47619 14.4762 6.53389 14.3684 6.63658C14.2606 6.73928 14.2 6.87857 14.2 7.02381V9.7619H18.225C18.4021 9.7619 18.5693 9.8396 18.6782 9.97251C18.7872 10.1054 18.8258 10.2787 18.7828 10.4423L17.6328 14.8233C17.5688 15.0671 17.3388 15.2381 17.075 15.2381H14.2V23.4524C14.2 23.7548 13.9426 24 13.625 24H9.025C8.70744 24 8.45 23.7548 8.45 23.4524V15.2381H5.575C5.25744 15.2381 5 14.9929 5 14.6905V10.3095C5 10.0071 5.25744 9.7619 5.575 9.7619H8.45V7.02381C8.45 5.4262 9.11638 3.89402 10.3025 2.76433ZM14.775 2.09524C13.4025 2.09524 12.0862 2.6145 11.1157 3.53878C10.1452 4.46307 9.6 5.71667 9.6 7.02381V10.3095C9.6 10.612 9.34256 10.8571 9.025 10.8571H6.15V14.1429H9.025C9.34256 14.1429 9.6 14.388 9.6 14.6905V22.9048H13.05V14.6905C13.05 14.388 13.3074 14.1429 13.625 14.1429H16.6261L17.4886 10.8571H13.625C13.3074 10.8571 13.05 10.612 13.05 10.3095V7.02381C13.05 6.5881 13.2317 6.17023 13.5552 5.86213C13.8787 5.55404 14.3175 5.38095 14.775 5.38095H17.65V2.09524H14.775Z"
      />
    </SvgIcon>
  );
}

export default Icon;
