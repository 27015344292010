import React from "react";
import { Divider, Grid } from "@mui/material";
import CircleButton from "components/forms/circle-button";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import { Button } from "components/forms/button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Zoom from "@mui/material/Zoom";
import Typography from "@mui/material/Typography";
import LinkIcon from "assets/icons/link";
import CloseIcon from "assets/icons/close";
import FacebookIcon from "assets/icons/facebook";
import TwitterIcon from "assets/icons/twitter";
import RedditIcon from "assets/icons/reddit";
import TelegramIcon from "assets/icons/telegram";
import WhatsappIcon from "assets/icons/whatsapp";
import InputAdornment from "@mui/material/InputAdornment";
import { InputOutlined } from "components/forms/input";
import {
  TwitterShareButton,
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share";
import useMediaQuery from "@mui/material/useMediaQuery";

const socialMedia = [
  {
    name: "Twitter",
    ShareButton: TwitterShareButton,
    Icon: TwitterIcon,
  },
  {
    name: "Facebook",
    ShareButton: FacebookShareButton,
    Icon: FacebookIcon,
  },
  // {
  //   name: "Reddit",
  //   ShareButton: RedditShareButton,
  //   Icon: RedditIcon,
  // },
  {
    name: "Telegram",
    ShareButton: TelegramShareButton,
    Icon: TelegramIcon,
  },
  {
    name: "Whatsapp",
    ShareButton: WhatsappShareButton,
    Icon: WhatsappIcon,
  },
];

const useStyles = makeStyles({
  root: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  button: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: 100,
  },
  disabled: {
    background: "#e6e6e6",
    border: "none",
  },
});

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

export default function ShareModal({ open, setOpen }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const [copied, setCopied] = React.useState(false);

  const handleCopy = () => {
    const text = window.location.href;

    navigator.clipboard.writeText(text);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: {
          background: "rgba(0,0,0, 0.25)",
          backdropFilter: "blur(5px)",
          webkitBackdropFilter: "blur(5px)",
        },
      }}
      sx={{
        position: "fixed",
        right: 0,
        bottom: 0,
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Zoom in={open}>
        <Box
          sx={{
            zIndex: 2000,
            maxWidth: 600,
            bgcolor: "background.paper",
            border: "1px solid",
            borderColor: "divider",
            boxShadow: 24,
            mx: 2,
            p: 4,
            borderRadius: "15px",
            position: "relative",
          }}
        >
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 20,
              top: 20,
              p: 2,
            }}
          >
            <CloseIcon color="disabled" sx={{ fontSize: 18 }} />
          </IconButton>
          <Grid container spacing={2}>
            <Grid item xs={12} container justifyContent="center">
              <Typography
                variant="h5"
                color="primary"
                sx={{
                  lineHeight: 1,
                  fontWeight: "bold",
                  mb: 1,
                }}
              >
                Share this article!
              </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Divider
                sx={{
                  width: "80%",
                  border: "none",
                  borderBottom: "2px dashed",
                  borderColor: "divider",
                }}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Typography
                variant="body1"
                sx={{ textAlign: "center", maxWidth: 400 }}
              >
                If you found this article is helpful, Please share it with your
                friends. Thank you! :D
              </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent="center" sx={{ my: 2 }}>
              {socialMedia.map(({ name, ShareButton, Icon }, index) => (
                <>
                  <Box sx={{ mx: 1 }}>
                    <ShareButton
                      url={
                        typeof window === "undefined"
                          ? ""
                          : window.location.href
                      }
                    >
                      <CircleButton Icon={Icon} tooltip={name} />
                    </ShareButton>
                  </Box>
                  {isMobile && index === 2 && <br />}
                </>
              ))}
            </Grid>
            <Grid item xs={12} container spacing={0}>
              <Grid item xs>
                <InputOutlined
                  disabled
                  value={typeof window === "undefined" ? "" : window.location}
                  placeholder={
                    typeof window === "undefined" ? "" : window.location.href
                  }
                  startAdornment={
                    <InputAdornment position="start">
                      <LinkIcon />
                    </InputAdornment>
                  }
                  classes={{
                    root: classes.root,
                    disabled:
                      theme.palette.mode == "light"
                        ? classes.disabled
                        : undefined,
                  }}
                />
              </Grid>
              <Button className={classes.button} onClick={() => handleCopy()}>
                {copied ? "Copied!" : "Copy"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Zoom>
    </Modal>
  );
}
