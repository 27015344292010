import React from "react";
import CircleButton from "components/forms/circle-button";
import LikeButton from "components/like-button";
import { Grid } from "@mui/material";
import ShareIcon from "assets/icons/share";
import CommentIcon from "assets/icons/comment";
import { useTheme } from "@mui/material/styles";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";

export default function FloatingButtons(props: {
  isLiked: boolean;
  likes: number | null;
  handleLike: () => void;
  handleShare: () => void;

  window?: () => Window;
}) {
  const theme = useTheme();

  const trigger = useScrollTrigger({
    target: props.window ? props.window() : undefined,
  });

  const scrollToComments = () => {
    document.querySelector("#comment-section")?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    // <Slide appear={false} direction="right" in={!trigger}>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          background: theme.palette.custom.appbar,
          padding: theme.spacing(1),
          border: "0.25px solid",
          borderColor: theme.palette.custom.appbarDivider,
          WebkitBackdropFilter: "blur(12px)saturate(200%)",
          backdropFilter: "blur(12px)saturate(200%)",
          boxShadow: "0px 8px 24px 2px rgba(0,0,0,0.25)",
          zIndex: 1100,
          position: "fixed",
          margin: "0 auto",
          borderRadius: 32,
          transition: "0.25s ease-out",
          bottom: trigger ? -100 : theme.spacing(2),
        }}
      >
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <CircleButton
              variant="no-border"
              tooltip="Comment"
              Icon={CommentIcon}
              onClick={scrollToComments}
              iconProps={{ style: { fontSize: 20 } }}
            />
          </Grid>

          <Grid item>
            <LikeButton
              // hideLikesCount
              isLiked={props.isLiked}
              likes={props.likes}
              handleLike={props.handleLike}
            />
          </Grid>

          <Grid item>
            <CircleButton
              variant="no-border"
              tooltip="Share"
              Icon={ShareIcon}
              onClick={props.handleShare}
              iconProps={{ style: { fontSize: 20 } }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
    // </Slide>
  );
}
