import { styled, Theme } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";

const Button = styled(MuiButton)(({ theme }: { theme: Theme }) => ({
  borderRadius: 32,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontWeight: "bold",
  padding: "10px 25px",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

export { Button };
